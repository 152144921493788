// reference: https://codepen.io/FredGenkin/pen/eaXYGV

@mixin iv-stars() {
  $starPercentage: calc(var(--star-rating) / 5 * 100%);
  display: inline-block;
  font-size: var(--star-size);
  line-height: 1;

  &::before {
    content: '★★★★★';
    background: linear-gradient(
      90deg,
      var(--star-background) $starPercentage,
      var(--star-mask) $starPercentage
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

@mixin iv-link() {
  &:hover {
    margin-top: calc(-1.5 * var(--spacing-30)) !important;
    padding-top: calc(2 * var(--spacing-30)) !important;
    a:first-child {
      visibility: visible;
      font-size: 1.5rem;
    }
  }
  a:first-child {
    cursor: pointer;
    word-wrap: break-word;
    font-size: 0;
    transition: opacity 0.1s linear;
    position: absolute;
    margin-left: -25px;
    width: 1.5rem;
    text-decoration: none;
    color: var(--light-blue-dark);
    visibility: hidden;
    :hover {
      visibility: visible;
      font-size: 1.5rem;
    }
    &::before {
      content: '\00B6';
    }
  }
}
