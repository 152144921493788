@keyframes fadein {
  0% {
    opacity: 0;
    transform: translateY(15px);
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.iv-animation-fade-in {
  animation: fadein 0.7s ease-in;
}

.iv-animate-button-ping:before {
  content: '';
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: inherit;
  height: inherit;
  background: var(--gradient-deep-blue-light-blue-turquoise);
  border-radius: 50%;
  animation: pulse-border 1600ms ease-out infinite;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.4);
    opacity: 0;
  }
}

.pricing-toggle {
  overflow: hidden;
}

.pricing-toggle::before {
  content: '';
  position: absolute;
  top: -110%;
  left: 40%;
  width: 20%;
  height: 337%;
  background: linear-gradient(#00ccff, #d400d4);
  animation: animate 3s linear infinite;
}

.pricing-toggle-inner {
  content: '';
  position: absolute;
  inset: 2px;
  border-radius: 80px;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(350deg);
  }
}

.clip-text {
  -webkit-background-clip: text !important; // sometimes, this property gets overridden and text does not get clipped, so adding important here
}

.user-seats {
  height: 100%;
  width: 100%;
  border-radius: 100px;
}

.seat-thumb {
  border-radius: 80px;
  background: #fff;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
  height: 100%;
  aspect-ratio: 1;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;

  &:active {
    cursor: grabbing;
  }
}

@keyframes shimmering {
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
}

.shimmer {
  display: inline-block;
  height: 100%;
  width: 100%;
  background: #bbbbbb;
  border-radius: 20px;
  background-image: linear-gradient(
    to right,
    #bbbbbb 0%,
    #888888 30%,
    #555555 50%,
    #888888 70%,
    #666666 100%
  );
  background-repeat: no-repeat;
  animation: shimmering 2s ease-in-out 0s infinite forwards;
  opacity: 0.25;
}
