@import '@invideoio/uniformd/styles/main';
@import 'variables';
@import 'mixins';
@import 'animations';
@import '~@invideoio/web-shared/styles/main';

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

html,
body {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

#fresh-chat-custom {
  z-index: 10;
}

.grecaptcha-badge {
  display: none !important;
}

.iv-no-scroll-bar-container {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.iv-no-scroll-bar-container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.iv-text-clip {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.iv-gradient-raspberry-deep-blue-blue {
  background-image: var(--gradient-raspberry-deepblue-blue);
}

.iv-gradient-blue-deepblue-raspberry {
  background-image: var(--gradient-blue-deepblue-raspberry);
}

.iv-title-gradient {
  background-image: var(--gradient-deepblue-raspberry-red);
  @extend .iv-text-clip;
}

.dark\:iv-title-gradient-dark {
  background-image: var(--gradient-blue-deepblue-raspberry);
  @extend .iv-text-clip, .iv-gradient-raspberry-deep-blue-blue;
}

.dark\:iv-title-gradient-raspberry-deepblue-blue {
  @extend .iv-gradient-raspberry-deep-blue-blue, .iv-text-clip;
}

.dark\:iv-gradient-blue-deepblue-raspberry {
  background-image: var(--gradient-blue-deepblue-raspberry);
}

.dark\:iv-gradient-raspberry-deep-blue-blue {
  background-image: var(--gradient-raspberry-deepblue-blue);
}

.iv-discount-gradient {
  @extend .iv-gradient-raspberry-deep-blue-blue, .iv-text-clip;
}

.iv-gradient-raspberry-violet {
  background: var(--gradient-raspberry-violet);
}

.iv-gradient-raspberry-red {
  background: var(--gradient-raspberry-red);
}

.iv-gradient-blue {
  background: linear-gradient(
    45deg,
    hsla(247, 76%, 56%, 1),
    hsla(201, 100%, 48%, 1),
    hsla(274, 87%, 47%, 1)
  );
}

.iv-gradient-dee-blue-blue-turquoise {
  background-image: var(--gradient-deep-blue-light-blue-turquoise);
  @extend .iv-text-clip;
}

.iv-gradient-deep-blue-blue-turquoise {
  background-image: var(--gradient-deep-blue-light-blue-turquoise);
}

.dark\:iv-gradient-deep-blue-blue-turquoise {
  background-image: var(--gradient-deep-blue-light-blue-turquoise);
}

.iv-creation-section-gradient {
  background-image: var(--creation-section-gradient);
}

.iv-fluid-animation-gradient {
  background: var(--fluid-animation-gradient);
}

.iv-edit-audio-gradient {
  background: var(--edit-audio-gradient);
}

.iv-gradient-border {
  background: var(--gradient-border);
}

.iv-gradient-light-blue {
  background: var(--gradient-light-blue);
}

.iv-text-shadow {
  text-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.iv-hero-video {
  position: absolute;
  top: 0;
  left: 1.4%;
  z-index: -1;
  width: 1624px;
  height: 856px;
  max-width: none;
}

.iv-app-promo-logo-box-shadow {
  box-shadow: 0 25px 100px rgba(0, 0, 0, 0.25);
}

.iv-rating-stars,
.iv-achievement-ratings,
.iv-testimonials-ratings {
  @include iv-stars();
}

.iv-template-hover {
  .iv-template-poster-image {
    z-index: 5;
  }
  .iv-template-cta {
    opacity: 0;
    transition: 0.4s;
  }

  &:hover {
    .iv-template-name {
      color: hsla(0, 0%, 0%, 0.85);
    }

    .iv-template-cta {
      opacity: 1;
    }

    .iv-template-poster-image {
      z-index: -5;
    }
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1479px) {
  .iv-hero-video {
    top: -15px;
    left: -0.5%;
  }

  .iv-achievement-ratings,
  .iv-testimonials-ratings {
    font-size: var(--body-2xl);
  }
}

@media only screen and (max-width: 1199px) {
  .iv-hero-video {
    top: -14px;
    left: -33%;
    width: 1136px;
    height: 800px;

    video {
      height: 100%;
      object-fit: cover;
    }
  }

  .iv-achievement-ratings,
  .iv-testimonials-ratings {
    font-size: var(--body-2xl);
  }
}

@media only screen and (max-width: 719px) {
  .iv-hero-video {
    left: -62%;
    width: 610px;
    height: 430px;
  }

  .iv-achievement-ratings,
  .iv-testimonials-ratings {
    font-size: var(--body-xl);
  }
}

.iv-link {
  @include iv-link();
}

ul,
ol {
  li {
    list-style: disc;
  }
}

.lihp-template-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -24px; /* gutter size offset */
  width: auto;
}

.lihp-template-grid_column {
  padding-left: 24px; /* gutter size */
}

.pricing-card-shadow {
  box-shadow: 0 12.5px 50px 0 rgba(0, 0, 0, 0.15);
}

img {
  display: inline;
}
