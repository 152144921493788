:root {
  --rating-star-default-size: 19px;

  --achievement-capterra: hsl(198, 79%, 67%);
  --achievement-capterra-mask: hsl(200, 62%, 82%);

  --achievement-trustpilot: hsl(160, 100%, 36%);
  --achievement-trustpilot-mask: hsl(159, 49%, 67%);

  --achievement-g2: hsl(9, 86%, 56%);
  --achievement-g2-mask: hsl(8, 86%, 77%);

  --testimonial-star-mask: hsl(246, 77%, 78%);

  --fluid-animation-gradient: hsl(188, 100%, 82%);

  --edit-audio-gradient: linear-gradient(
    237.22deg,
    var(--fluid-animation-gradient) 0%,
    hsl(317, 100%, 83%) 100%
  );
  --youtube-red: hsla(356, 86%, 55%, 1);
  --fb-blue: hsla(214, 89%, 52%, 1);

  --gradient-border: linear-gradient(
    315deg,
    hsl(253, 71%, 55%) 1%,
    hsl(236, 82%, 58%) 99.18%
  );

  --gradient-light-blue: linear-gradient(
    247.53deg,
    #0f73ff -33.39%,
    rgba(15, 115, 255, 0.26) 18.75%,
    rgba(65, 210, 255, 0.1) 70.89%
  );

  --creation-section-gradient: linear-gradient(
    232.1deg,
    var(--violet) 0%,
    var(--violet) 50%,
    var(--raspberry) 100%
  );
}
